<template>
  <div class="main-customer-wrapper">
    <Breadcrumb :breadcrumbs="breadcrumbs" mainLabelName="Профиль"></Breadcrumb>
    <a-tabs>
      <a-tab-pane key="1" tab="Личные данные ">
        <TabViewContainer>
          <Loader v-if="!this.$store.getters.getPersonalDataStatusLoading" />
          <SetPersonalDataCustomer
            v-if="this.$store.getters.getPersonalDataStatusLoading"
            :personalData="this.$store.getters.getPersonalData"
          />
        </TabViewContainer>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Контакты">
        <TabViewContainer>
          <Loader v-if="!this.$store.getters.getPersonalDataStatusLoading" />
          <AddContact  v-if="this.$store.getters.getPersonalDataStatusLoading" :personalData="this.$store.getters.getPersonalData" />
        </TabViewContainer>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import SetPersonalDataCustomer from '@/components/CustomerComponents/SetPersonalDataCustomer.vue'
import AddContact from '@/components/common/AddContact.vue'
import TabViewContainer from '@/components/common/TabViewContainer.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import EmptyVue from '@/components/common/EmptyVue.vue'
import Loader from '@/components/common/Loader.vue'

export default {
  mounted () {
    this.$store.dispatch('setPersonalData')
  },
  components: {
    TabViewContainer,
    Breadcrumb,
    SetPersonalDataCustomer,
    AddContact,
    EmptyVue,
    Loader
  },
  methods: {
    showConsole () {
    }
  },
  data () {
    return {
      breadcrumbs: [
        {
          id: 1,
          name: 'Главная',
          link: '/main'
        },
        {
          id: 2,
          name: 'Профиль',
          link: '/set_profile'
        }
      ],

      form: {
        name: '',
        surname: '',
        middlename: '',

        companyName: '',
        password: '',
        password_confirmation: ''
      }
    }
  }
}
</script>

<style>
.main-customer-wrapper {
  background-color: white;
  width: 100%;
  height: 100%;
}
</style>
