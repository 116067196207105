<template>
  <div class="add__contact__container">
    <a-form :form="personalForm">
      <div class="add__contact__container__label">Контакты</div>
      <!-- :validateStatus="validationFields.statusPhone"
        :help="validationFields.helpPhone" -->
      <a-form-item>
        <a-input
          :value="personalForm.phone"
          v-on:input="updateFormPhone($event)"
          size="large"
          placeholder="Телефон"
          @keypress="validateNumber"
        >
          <!-- v-on:keypress.prevent="validateNumber" -->
          <template v-slot:prefix> </template>
        </a-input>
      </a-form-item>
      <a-form-item
        :validateStatus="validationFields.statusEmail"
        :help="validationFields.helpEmail"
      >
        <a-input
          :value="personalForm.email"
          v-on:input="updateFormEmail($event)"
          size="large"
          placeholder="Почта"
        >
          <template v-slot:prefix> </template>
        </a-input>
      </a-form-item>

      <a-form-item>
         <Button @click="sendNewForm" :buttonClass="'red-antd-button34'">Сохранить</Button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { validateEmail } from '@/helpers/validation'
import { getUUID, getBearer } from '@/helpers/authHelper'
import Button from '@/components/common/Button.vue'

export default {
  components: {
    Button
  },
  props: ['personalData'],
  data () {
    return {
      personalForm: {
        phone: this.personalData.phone,
        email: this.personalData.email
      },
      validationFields: {
        // statusPhone: "success",
        // helpPhone: "",
        statusEmail: 'success',
        helpEmail: ''
      }
    }
  },
  methods: {
    validateNumber (e) {
      let keyCode = e.keyCode ? e.keyCode : e.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        e.preventDefault()
      }
    },
    updateFormPhone (e) {
      this.personalForm.phone = e.target.value

      // this.validationFields.statusPhone = "success";
      // this.validationFields.helpPhone = "";
      // }
    },
    updateFormEmail (e) {
      this.personalForm.email = e.target.value
      this.validationFields.statusEmail = 'success'
      this.validationFields.helpEmail = ''
    },

    sendNewForm () {
      // if (this.personalForm.phone == "") {
      //   this.validationFields.statusPhone = "error";
      //   this.validationFields.helpPhone = "Пустое поле";
      // }
      if (this.personalForm.email === '') {
        this.validationFields.statusEmail = 'error'
        this.validationFields.helpEmail =
          'Нельзя сохранить пустое значение электронной почты'
      }
      if (!validateEmail(this.personalForm.email)) {
        this.validationFields.statusEmail = 'error'
        this.validationFields.helpEmail = 'Некорректная почта'
      }

      if (
        this.validationFields.statusEmail === 'success'
        // && this.validationFields.statusPhone == "success"
      ) {
        const url = process.env.VUE_APP_SERVER_URL + `/users/${getUUID()}`
        fetch(url, {
          method: 'PUT',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getBearer()
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify(this.personalForm)
        })
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            if (data.success) {
              this.$notification.open({
                message: 'Данные пользователя успешно обновлены!'
              })
            } else {
              this.$notification.open({
                message: 'Произошла ошибка обновленя данных',
                data
              })
            }
          })
      }
    }
  }
}
</script>

<style lang="scss">
.add__contact__container {
  width: 100%;
  padding: 50px 40% 50px 30px;

  background-color: white;
  &__label{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
    margin-bottom: 23px;
  }
}
@media (max-width: 992px) {
  .add__contact__container{
    padding-right: 30%;
  }
}
@media (max-width: 575px) {
  .add__contact__container{
    padding: 20px;
  }
}
</style>
