<template>
  <div class="clearfix">
    <a-upload
      name="avatar"
      listType="picture-card"
      :fileList="fileList"
      @preview="handlePreview"
      @change="handleChange"
      :before-upload="beforeUpload"
    >
      <div v-if="fileList.length < 1">
        <a-icon type="plus" />
        <div class="ant-upload-text">Upload</div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  props: ['image'],
  data () {
    return {
      previewVisible: false,
      previewImage: '',
      fileList: this.getImage(this.image)
    }
  },
  methods: {
    getImage (imageName) {
      if (imageName || imageName === '') {
        return [
          {
            uid: -1,
            name: 'xxx.png',
            status: 'done',
            url: process.env.VUE_APP_SERVER_URL_FILE + imageName
          }
        ]
      } else {
        return []
      }
    },
    handleCancel () {
      this.previewVisible = false
    },
    handlePreview (file) {
      this.previewImage = file.url || file.thumbUrl
      this.previewVisible = true
    },
    handleChange ({ fileList }) {
      if (fileList.length === 0) {
        this.fileList = []
        this.$emit('setAvatar', '')
        return
      }
    },

    beforeUpload (file) {
      this.fileList = [file]
      this.submitFile(file)
      return false
    },
    submitFile (file) {
      this.fileList = [file]

      let formData = new FormData()

      formData.append('images[' + 0 + ']', file)

      axios
        .post(process.env.VUE_APP_SERVER_URL + '/images', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          this.$emit('setAvatar', res.data.data[0])
          this.fileList = this.getImage(res.data.data[0])
        })
        .catch(function () {
          console.log('FAILURE!!')
        })
    }
  }
}
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
