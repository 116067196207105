<template>
  <div class="customer__personal__data">
    <a-form class="customer__personal__data__container">
      <a-form-item>
        <div class="form-item-label">Личные данные</div>
      </a-form-item>

      <a-form-item :validateStatus="statusName" :help="helpName">
        <a-input
          :value="personalForm.name"
          v-on:input="updateFormName($event)"
          size="large"
          placeholder="Имя"
        />
      </a-form-item>

      <a-form-item :validateStatus="statusSurname" :help="helpSurname">
        <a-input
          :value="personalForm.surname"
          v-on:input="updateFormSurname($event)"
          size="large"
          placeholder="Фамилия"
        />
      </a-form-item>
      <a-form-item :validateStatus="statusMiddlename" :help="helpMiddlename">
        <a-input
          :value="personalForm.middlename"
          v-on:input="updateFormMiddlename($event)"
          size="large"
          placeholder="Отчество"
        />
      </a-form-item>
      <a-form-item>
        <UploadAvatar
          name="Загрузить Фото"
          :image="personalForm.image"
          @setAvatar="unloadFile"
        ></UploadAvatar>
      </a-form-item>
      <a-form-item :validateStatus="statusCompany" :help="helpCompany">
        <a-input
          :value="personalForm.companyName"
          v-on:input="updateFormCompany($event)"
          size="large"
          placeholder="Компания"
        />
      </a-form-item>

      <a-form-item>
        <div class="form-item-label">Пароль</div>
      </a-form-item>

      <a-form-item>
        <a-input-password
          :value="personalForm.password"
          v-on:input="updateFormPassword($event)"
          placeholder="Пароль"
          size="large"
        />
      </a-form-item>
      <a-form-item>
        <a-input-password
          :value="personalForm.password_confirmation"
          v-on:input="updateFormPasswordConfirmation($event)"
          placeholder="Повторите Пароль"
          size="large"
        />
      </a-form-item>
      <a-form-item>
        <Button @click="sendNewForm" :buttonClass="'red-antd-button34'">Сохранить</Button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { getUUID, getBearer } from '@/helpers/authHelper'
import Button from '@/components/common/Button.vue'
import UploadAvatar from '@/components/common/UploadAvatar.vue'

export default {
  components: {
    UploadAvatar,
    Button
  },
  props: ['personalData'],
  data () {
    return {
      personalForm: this.personalData,
      statusName: 'success',
      helpName: '',
      statusSurname: 'success',
      helpSurname: '',
      statusMiddlename: 'success',
      helpMiddlename: '',
      statusCompany: 'success',
      helpCompany: '',
      statusPassword: 'success',
      helpPassword: '',
      statusPasswordRepeat: 'success',
      helpPasswordRepeat: ''
    }
  },
  name: 'SetPersonalDataMaster',
  methods: {
    unloadFile (file) {
      this.personalForm.image = file
    },
    updateFormName (e) {
      this.personalForm.name = e.target.value
      this.statusName = 'success'
      this.helpName = ''
    },
    updateFormSurname (e) {
      this.personalForm.surname = e.target.value
      this.statusSurname = 'success'
      this.helpSurname = ''
    },
    updateFormMiddlename (e) {
      this.personalForm.middlename = e.target.value
      this.statusMiddlename = 'success'
      this.helpMiddlename = ''
    },
    updateFormCompany (e) {
      this.personalForm.companyName = e.target.value
      this.statusCompany = 'success'
      this.helpCompany = ''
    },
    updateFormPassword (e) {
      this.personalForm.password = e.target.value
      this.statusPassword = 'success'
      this.helpPassword = ''
    },
    updateFormPasswordConfirmation (e) {
      this.personalForm.password_confirmation = e.target.value
      this.statusPasswordRepeat = 'success'
      this.helpPasswordRepeat = ''
    },

    sendNewForm () {
      if (this.personalForm.name === '') {
        this.statusName = 'error'
        this.helpName = 'Empty name'
      }
      if (this.personalForm.surname === '') {
        this.statusSurname = 'error'
        this.helpSurname = 'Empty Surname'
      }
      if (this.personalForm.middlename === '') {
        this.statusMiddlename = 'error'
        this.helpMiddlename = 'Empty Middlename'
      }
      if (
        this.statusName === 'success' &&
        this.statusSurname === 'success' &&
        this.statusMiddlename === 'success' &&
        this.personalForm.password === this.personalForm.password_confirmation
      ) {
        const url = process.env.VUE_APP_SERVER_URL + `/users/${getUUID()}`

        let sendForm
        if (this.personalForm.description) {
          sendForm = this.personalForm
        } else {
          sendForm = {
            name: this.personalForm.name,
            middlename: this.personalForm.middlename,
            surname: this.personalForm.surname,
            companyName: this.personalForm.companyName,
            image: this.personalForm.image
          }
        }
        fetch(url, {
          method: 'PUT',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getBearer()
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify(sendForm)
        })
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            if (data.success) {
              this.$notification.open({
                message: 'Данные пользователя успешно обновлены!'
              })
            } else {
              this.$notification.open({
                message: 'Произошла ошибка обновленя данных',
                data
              })
            }
          })
      }
    }
  }
}
</script>

<style lang="scss">
.customer__personal__data{
  width: 100%;
  height: 100%;

  padding: 50px 25% 0 33px;
  background-color: white;
  &__container {
    width: 80%;
    height: 100%;
  }
  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
  }
}

.form-item-label{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

@media (max-width: 992px) {
  .customer__personal__data{
    padding-right: 33px;
  }
}
@media (max-width: 575px) {
  .customer__personal__data{
    padding: 20px;
    &__container {
      width: 100%;
    }
  }
}
</style>
